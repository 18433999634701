<template>
  <div id="membershipcard">
    <el-row style="background-color: #ffffff; padding-left: 20px">
      <content-title :nav="nav"></content-title>
    </el-row>
    <div style="padding: 0 20px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="会员卡列表" name="0">
          <member-list></member-list>
        </el-tab-pane>
          <el-tab-pane label="会员卡设置" name="1">
          <memberSetting></memberSetting>
        </el-tab-pane>
        <el-tab-pane label="会员卡权益" name="2">
          <cardRight></cardRight>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import memberSetting from "./components/memberCard/memberSetting.vue";
import memberList from "./components/memberCard/memberList.vue";
import cardRight from "./components/memberCard/cardRight.vue";
export default {
  components: {
    memberList,
    memberSetting,
    cardRight,
  },
  data() {
    return {
      activeName: 0,
      nav: { firstNav: "卡券管理中心", secondNav: "会员卡管理" },
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀",
        },
      ],
    };
  },
  methods: {
    handleClick() {},
    currentPageChange() {},
  },
};
</script>

<style lang="less">
#membershipcard {
  background-color: #ffffff;
}

// .el-form-item {
//  margin-right: 50px !important;
// }

.cell {
  text-align: center;
}
</style>
