<template>
  <div>
    <!-- <el-tabs v-model="activeName1" @tab-click="handleClick">
        <el-tab-pane label="新售" name="0"></el-tab-pane>
        <el-tab-pane label="激活" name="1"></el-tab-pane>
        <el-tab-pane label="续卡" name="2"></el-tab-pane>
        <el-tab-pane label="本月到期" name="3"></el-tab-pane>
      </el-tabs> -->
    <div>
      <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
        <el-form-item label="用户手机号">
          <user-select :userid.sync="user_id"></user-select>
        </el-form-item>
        <el-form-item label="卡类型">
          <el-select
            v-model="cardType"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option
              v-for="item in cardTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道来源">
          <el-input v-model="options.channle" ></el-input>
        </el-form-item>
        <el-form-item label="所属顾问">
          <el-input v-model="options.gw" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
          style="background-color: #ff7e00; color: #ffffff;border:0;"
            @click="search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-row>
        <el-table :data="user_card_list" v-loading="is_loading">
          <el-table-column label="卡号" prop="id" width="80"></el-table-column>
          <el-table-column
            label="类型"
            prop="type"
            width="120"
          ></el-table-column>
          <el-table-column label="发卡时间"   width="200" prop="start_time"></el-table-column>
          <el-table-column
            label="用户昵称"
            prop="map.userinfo.nick_name"
          ></el-table-column>
          <el-table-column
            label="用户手机号"
            prop="map.userinfo.mobile"
          ></el-table-column>
          <el-table-column label="权益点数" align="center">
            <template slot-scope="{ row }">
              <div class="flex">
                <span>{{ row.map.point }}</span>
                <el-button type="text" @click="exchange(row)">调整</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道来源" prop=""></el-table-column>
          <el-table-column label="所属销售" prop=""></el-table-column>
          <el-table-column
          width="200"
            label="到期时间"
            prop="end_time"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="card_total"
        ></paging-fy>
      </el-row>
    </div>

    <el-dialog title="调整权益点" :visible.sync="dialogVisible" width="40%">
      <div>
        <el-form :inline="true" :model="changePoint" class="demo-form-inline">
          <el-form-item label="增加+">
            <el-input
              v-model="changePoint.addPoint"
              placeholder="增加点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.addDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>

          <el-form-item label="减少-">
            <el-input
              v-model="changePoint.reducePoint"
              placeholder="减少点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.reduceDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="pointChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
    name:'memberList',
  data() {
    return {
      activeName1:0,
      user_card_list: [],
      card_total: 0,
      is_loading: false,
      currentPage: 1,
      index: 1,
      user_id:"",
      options: {},
      dialogVisible: false,
      changePoint: {
        addPoint: "",
        addDesc: "",
        reducePoint: "",
        reduceDesc: "",
      },
      changeId: "",
      cur: {},
      cardTypes: [
        {
          label: "优学卡",
          value: "优学卡",
        },
        {
          label: "尊享卡",
          value: "尊享卡",
        },
      ],
      cardType: "优学卡",
    };
  },
  mounted() {
    this.getUserCardList();
  },
  methods: {
    getZhuanxiangId() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      return this.$axios.get(url, {
        params: {
          pageSize: 10,
          status: 1,
          currentPage: 1,
          platform: "体育宝",
          member_name: "优学卡",
        },
      });
    },
    changeIndex(index) {
      this.currentPage = 1;
      this.options = {};
      this.index = index;
      switch (index) {
        case 1:
      }
    },
    search() {
      this.getUserCardList();
    },
    getUserCardList(num) {
      this.is_loading = true;
      let url = "/user/userMember/queryManagerListPage";

      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            type: this.cardType,
           user_id: this.user_id || null
          },
        })
        .then((res) => {
          this.card_total = res.data.data.total;
          this.user_card_list = res.data.data.rows;
          this.is_loading = false;
        });
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getUserCardList();
    },
    exchange(row) {
      this.cur = row;
      this.dialogVisible = true;
    },
    pointChange() {
      let url = "";
      let num = 0;
      let reason = "";
      if (
        this.changePoint.addPoint == "" &&
        this.changePoint.reducePoint == ""
      ) {
        this.$message({
          message: "请输入要调整的权益点数量",
          type: "info",
        });
        return;
      }
      if (
        this.changePoint.addDesc == "" &&
        this.changePoint.reduceDesc == ""
      ) {
        this.$message({
          message: "请填写调整原因",
          type: "info",
        });
        return;
      }
      if (this.changePoint.addPoint != "" && this.changePoint.addPoint > 0) {
        url = "/user/userPoint/updateAddPoint";
        num = this.changePoint.addPoint;
        reason = this.changePoint.addDesc;
      } else if (
        this.changePoint.reducePoint != "" &&
        this.changePoint.reducePoint > 0
      ) {
        url = "/user/userPoint/updateSubPoint";
        num = this.changePoint.reducePoint;
        reason = this.changePoint.reduceDesc;
      } else {
        this.$message({
          message: "请输入正确的点数",
          type: "info",
        });
        return;
      }
      this.$axios({
        url,
        data: {
          id: this.cur.point_id,
          point: Number(num),
          reason,
          admin_id:JSON.parse(localStorage.getItem("managerInfo")).id ,
          admin_name:JSON.parse(localStorage.getItem("managerInfo")).name
        },
        method: "post",
      }).then((res) => {
        this.changePoint = {
          addPoint: "",
          addDesc: "",
          reducePoint: "",
          reduceDesc: "",
        };
        if (res.data.code == 0) {
          this.getUserCardList();
          this.dialogVisible = false;
        }
        this.$message({
          message: res.data.message,
          type: "info",
        });
      });
    },
    handleClick(){

    }
  },
};
</script>
  
  <style scoped>
.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
  